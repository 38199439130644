import React from 'react'
import '../../../New css/Digital Talent Solutions/DtsInfo2.css'
import { FadeUp } from '../../Animations/Animations'

export default function DtsInfo2() {
    return (
        <>
            <div className="dataenginfomaincontainer">
                <div className="dataenginfochildcontainer">
                    <FadeUp>
                        <div className="dataenginfomainheader">
                            <p>Industry-Specific Expertise</p>
                        </div>
                    </FadeUp>
                    <div className="dataenginfomaindesc">
                        <p>
                            <FadeUp>
                                Our experience spans a wide range of industries, providing specialized digital talent solutions for: <br /><br />
                            </FadeUp>
                            <ul>
                                <FadeUp>
                                    <li>Banking and Financial Services</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Insurance</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Healthcare</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Life Sciences</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Technology</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Media</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Telecommunications</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Consumer Goods</li>
                                </FadeUp>
                                <FadeUp>
                                    <li>Retail</li>
                                </FadeUp>
                            </ul> <br /><br />
                            <FadeUp>
                                We understand the unique challenges of each industry and bring in technology talent that meets those specific needs.
                            </FadeUp>

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
