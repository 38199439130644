import React from 'react'
import { FadeUp } from '../../../Animations/Animations'

export default function Sap1hero() {
    return (
        <>
            <div className="dataengheromaincontainer">
                <div className="dataengherodiv1">
                    <div className="dataengherodiv1childcontainer">
                        <div className="dataengheromainheader">
                            <p>SAP S/4 HANA</p>
                        </div>
                        <div className="dataengheromainsubheader">
                            <p>The intelligent ERP for real-time business – transact, analyze, predict.</p>
                        </div>
                    </div>
                </div>
                <FadeUp>
                    <div className="dataengherodiv2">
                        <div className="dataengheromaindesc">
                            <p>
                                SAP S/4HANA, powered by the SAP HANA in-memory database, supports real-time business transactions and data analysis. This next-generation ERP solution drives SAP's digital transformation strategy, enabling businesses to enhance flexibility and adaptability to changing conditions, embodying SAP's vision of the intelligent enterprise.<br /><br />
                                At Thrivex Technologies, we guide you through the transition to S/4HANA, empowering your organization to fully embrace SAP's intelligent enterprise vision. We achieve this by integrating cutting-edge digital technologies and advanced analytics with your SAP S/4HANA solution, maximizing your business's agility and effectiveness.
                            </p>
                        </div>
                    </div>
                </FadeUp >
            </div>
        </>
    )
}
