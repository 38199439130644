import React, { useEffect, useRef } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from '../../../../Images/Product Engineering/Integration/logo1.png'
import img2 from '../../../../Images/Product Engineering/Integration/logo2.png'
import img3 from '../../../../Images/Product Engineering/Integration/logo3.png'
import img4 from '../../../../Images/Product Engineering/Integration/logo4.png'
import '../../../../New css/CardSlider/CardSlider.css'
import { FadeUp } from '../../../Animations/Animations';

export default function IntegrationInfo() {

    const cardsinfo2 = [
        {
            logo: img1,
            head: 'API-Led Integration ',
            desc1: 'Transforming Challenges into Insights',
            desc: 'Our API solutions synchronize data, boost productivity, and drive revenue. Using off-the-shelf tools or customized frameworks, we tailor solutions to your business needs. Our API-led approach connects disparate technologies through reusable services, enhancing automation, security, and efficiency while reducing costs and improving partner interactions.'
        },
        {
            logo: img2,
            head: 'Data Integration ',
            desc1: 'Modernize Your Data Infrastructure',
            desc: 'Achieve platform independence with seamless data integration, consolidating diverse data sources to support informed decision-making. This unified approach drives business growth by leveraging comprehensive insights, enhancing agility to adapt to evolving needs, and fostering innovation through advanced technologies.'
        },
        {
            logo: img3,
            head: 'Enterprise App Integration',
            desc1: 'Build a Strong Data Foundation for Your Data Strategy',
            desc: "Implement real-time, message-based integration between applications to streamline IT landscapes, automate processes, and minimize redundancies. This enhances operational efficiency and simplifies management, ensuring smoother business operations."
        },
        {
            logo: img4,
            head: 'SaaS Platform Integration ',
            desc1: 'Transforming Challenges into Insights',
            desc: 'Integrate cloud applications with on-premises systems to ensure robust data governance while providing enhanced access, flexibility, and scalability. This integration empowers businesses to leverage cloud advantages while maintaining control over critical data and adapting swiftly to changing business requirements.'
        },
    ]

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const settings = {
        dots: true,
        className: "center",
        centerMode: true,
        centerPadding: '0px',
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };

    const calculateCardHeight = () => {
        let elements = document.getElementsByClassName('slidercard');
        let maxHeight = 0;

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.clientHeight > maxHeight) {
                maxHeight = element.clientHeight
            }
        }

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            element.style.height = `${maxHeight + 20}px`
        }
    }

    useEffect(() => {
        calculateCardHeight();
    }, [])

    return (
        <>
            <FadeUp>
                <div className="slider-container" >
                    <div className="dataenginfodiv1" >
                        <div className="dataenginfomainheader">
                            <p>Our Integration Services</p>
                        </div>
                        <div className="dataenginfomaindesc">
                            <p>We offer a comprehensive suite of integration solutions to meet your specific needs, from connecting cloud platforms to streamlining internal workflows:</p>
                        </div>
                    </div>
                    <Slider ref={slider => {
                        sliderRef = slider;
                    }} {...settings}>
                        {cardsinfo2.map((option, index) => (
                            <div id="qlikcard" className="slidercard">
                                <div className="slidercardsection1">
                                    <div className="slidercardlogo">
                                        <img src={option.logo} alt="" />
                                    </div>
                                    <div className="slidercardheader">
                                        <p>{option.head}</p>
                                    </div>
                                </div>
                                <div className="slidercardsection2">
                                    <div className="slidercarddesc">
                                        <p>{option.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    <div style={{ textAlign: "center" }}>
                        <div className="slider-buttons">
                            <i id="leftslidebtn" class="fa-solid fa-chevron-left leftslidebtn" onClick={previous}></i>
                            <i id="rightslidebtn" class="fa-solid fa-chevron-right rightslidebtn" onClick={next}></i>
                        </div>
                    </div>
                </div>
            </FadeUp>
        </>
    )
}
